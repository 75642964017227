<template>
<div class="liff-course-instant-survey-list-page">
  <div class="survey-card-list">
    <template v-if="!loading && instantSurveysData.length">
      <a-row>
        <a-col
          v-for="item in instantSurveysData"
          :span="24"
        >
          <div class="survey-card">
            <div class="card-title">
              <span class="title-name">{{ item.name }}</span>
            </div>

            <div class="card-actions">
              <a-button
                class="action-btn"
                type="primary"
                :style="{ background: '#FF8A00', borderColor: '#FF8A00' }"
                @click="toInstantSurveyPage(item.id)"
              >查看調查</a-button>
            </div>
          </div>
        </a-col>
      </a-row>
    </template>

    <template v-else>
      <a-empty
        class="py-6"
        :image="simpleImage"
        description="目前沒有即時調查"
        :style="{ fontSize: '1.3rem', color: '#656565' }"
      />
    </template>
  </div>
</div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import api from '@/apis'
import DateUtil from '@/utils/DateUtil'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();

    /* Loading */
    const loading = ref(true);

    /* Data */
    const instantSurveysData = ref([]);

    /* Api */
    const getInstantSurveysData = (async () => {
      const response = await api.v1.course.instantSurveyList(route.params.course_id);
      instantSurveysData.value = response.data.result;
      console.log(instantSurveysData.value)
    });

    /* Mounted */
    onMounted(async () => {
      await getInstantSurveysData();

      loading.value = false;
    });


    return {
      /* Loading */
      loading,

      /* Data */
      instantSurveysData,

      /* Empty */
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  methods: {
    /* Router */
    toInstantSurveyPage(id) {
      this.$router.push({
        name: 'LiffInstantSurveyPage',
        params: {
          course_id: this.$route.params.course_id,
          id: id
        }
      });
    }
  }
})
</script>

<style lang="scss">
.liff-course-instant-survey-list-page {
  .survey-card-list {
    .survey-card {
      background: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      padding: 4px;
      margin-bottom: 20px;

      .card-title {
        padding: 12px 20px;
        border-bottom: 1px solid #E0E0E0;
        font-weight: bold;

        .title-number {
          color: #FF8A00;
          font-size: 1.3rem;
          margin-right: 4px;
        }

        .title-name {
          color: #212121;
          font-size: 1.25rem;
        }
      }

      .card-body {
        padding: 12px 20px;
        border-bottom: 1px solid #E0E0E0;

        .body-header {
          margin-bottom: 8px;
          font-size: 1.3rem;
          
          .header-time {
            color: #FF8A00;
            font-weight: 500;
          }

          .header-name {
            color: #FF8A00;
            font-weight: 400;
            float: right;
          }
        }
        
        .body-description {
          color: #424242;
          font-size: 1rem;
        }
      }

      .card-actions {
        padding: 12px 20px;

        .action-btn {
          width: 100%;
          font-size: 1.2rem;
          height: 44px;
        }
      }
    }
  }

  .page__actions {
    width: 100%;
    height: 50px;
    position: fixed;
    left: 0;
    bottom: 0;

    .action-btn {
      display: inline-block;
      width: 50%;
      height: 50px;
      background: white;
      box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.05);
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 50px;
      text-align: center;

      &.btn--disabled {
        color: #424242;
      }
    }
  }
}
</style>